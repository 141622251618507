import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage, ref } from 'firebase/storage';

export const useFirebase = () => {
  const config = useRuntimeConfig();
  const firebaseConfig: FirebaseOptions = {
    apiKey: 'AIzaSyBlg4lqCFAizOrkbw_2MOgqutdUfMUJ3iM',
    authDomain: 'rendezvous-1170e.firebaseapp.com',
    projectId: 'rendezvous-1170e',
    storageBucket: 'rendezvous-1170e.appspot.com',
    messagingSenderId: '428779114903',
    appId: '1:428779114903:web:b6d95b6e7bc4386780b4d2',
    measurementId: 'G-9T5X1RGTC6',
  };

  const firebaseApp = initializeApp(firebaseConfig);
  const firestore = getFirestore(firebaseApp);
  const functions = getFunctions(firebaseApp);
  const storage = getStorage(firebaseApp);
  //const auth = getAuth(firebaseApp);

  return {
    //auth,
    firebaseApp,
    firestore,
    functions,
    storage,
    ref,
  };
};
