import { initializeApp } from 'firebase/app';
import { getStorage, ref } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAuth, initializeAuth } from 'firebase/auth';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const firebaseConfig = {
    apiKey: 'AIzaSyBlg4lqCFAizOrkbw_2MOgqutdUfMUJ3iM',
    authDomain: 'rendezvous-1170e.firebaseapp.com',
    projectId: 'rendezvous-1170e',
    storageBucket: 'rendezvous-1170e.appspot.com',
    messagingSenderId: '428779114903',
    appId: '1:428779114903:web:b6d95b6e7bc4386780b4d2',
    measurementId: 'G-9T5X1RGTC6',
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const { initUser } = useAuth();
  initUser();
});
