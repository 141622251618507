import { defineStore } from 'pinia';
import { SnackbarOptions } from '~~/types';

export const useSnackStore = defineStore('snack', () => {
  const snackbar = ref({} as SnackbarOptions);
  const showSnackbar = (options: SnackbarOptions) => {
    console.warn('showSnackbar', options);
    snackbar.value = { ...options, visible: true };
  };
  const hideSnackbar = () => {
    snackbar.value = { ...snackbar.value, visible: false };
  };
  return { snackbar, showSnackbar };
});
