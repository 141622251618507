import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import vueuse_head_plugin_D7WGfuP1A0 from "/vercel/path0/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import router_Pg0DINazwm from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import prefetch_client_3cwHvxIDEX from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import plugin_client_Fdvg3o8568 from "/vercel/path0/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/.nuxt/formkitPlugin.mjs";
import firebaseAuth_client_OP6eaV11Gv from "/vercel/path0/plugins/firebaseAuth.client.ts";
import vuetify_7h9QAQEssH from "/vercel/path0/plugins/vuetify.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  vueuse_head_plugin_D7WGfuP1A0,
  router_Pg0DINazwm,
  prefetch_client_3cwHvxIDEX,
  plugin_client_Fdvg3o8568,
  formkitPlugin_pZqjah0RUG,
  firebaseAuth_client_OP6eaV11Gv,
  vuetify_7h9QAQEssH
]