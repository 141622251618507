<template>
  <Suspense>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <template #fallback>
      <div class="loading">
        <v-progress-circular indeterminate color="primary" />
      </div>
    </template>
  </Suspense>
  <SnackBar
    :visible="showSnackBar"
    :text="snackBarText"
    :timeout="snackBarTimeout"
  />
</template>

<script setup>
import { useSnackStore } from './stores/snack';

const snack = useSnackStore();
const snackBarText = computed(() => snack.snackbar.text);
const snackBarTimeout = computed(() => snack.snackbar.timeout);
const showSnackBar = computed(() => snack.snackbar.visible);

useHead({
  meta: [
    {
      name: 'viewport',
      content:
        'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0',
    },
  ],
  title: 'Rendezvous & Review',
  link: [
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
    },
  ],
});
</script>
