<template>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    {{ text }}

    <template v-slot:actions>
      <v-btn v-if="action" :color="action.color" variant="text" @click="action?.onClick">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">

interface SnackbarOptions {
  visible?: boolean;
  text?: string;
  timeout?: number;
  color?: string;
  action?: {
    text: string;
    color?: string;
    onClick: () => void;
  };
}

const props = defineProps<SnackbarOptions>();

const snackbar = ref(false);
watch(
  () => props.visible,
  (val) => {
    snackbar.value = val;
  }
);

</script>
