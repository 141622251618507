import { defineStore } from 'pinia';
import { UserInfo } from '~~/types';
import { ref } from 'vue';
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { getDownloadURL, getMetadata, uploadString } from 'firebase/storage';
import { useLocalStorage } from '@vueuse/core';
import { User } from 'firebase/auth';

export const useUserStore = defineStore('user', () => {
  const user = useLocalStorage('user', ref<UserInfo>({}));
  const users = useLocalStorage('users', ref<Record<string, UserInfo>>({}));

  const clearUser = () => {
    user.value = {};
  };

  const setUser = (newUser: UserInfo) => {
    user.value = newUser;
  };

  const { firestore } = useFirebase();

  const currentUserId = computed(() => user.value?.id);

  const createProfile = async (user: User) => {
    const docRef = doc(firestore, 'users', user.uid);
    try {
      await setDoc(docRef, {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoUrl: user.photoURL,
      } as UserInfo);
    } catch (e) {
      return false;
    }
  };

  const getUserProfile = async (userId: string, forceRefresh?: boolean) => {
    if (userId == undefined) return null;
    const profile = users.value && users.value[userId];
    if (profile && !forceRefresh) {
      return profile;
    } else {
      const docRef = doc(firestore, 'users', userId);
      const userSnapshot = await getDoc(docRef);
      if (userSnapshot.exists()) {
        const dbUser = { ...userSnapshot.data(), id: userSnapshot.id };
        users.value[userSnapshot.id] = dbUser;
        console.warn('User found', dbUser);
        return dbUser as UserInfo;
      } else {
        console.warn('User not found');
        return null;
      }
    }
  };

  const updateProfile = async (newProfile: UserInfo) => {
    const userId = currentUserId.value;
    if (!userId) return;
    const docRef = doc(firestore, 'users', userId);
    try {
      await updateDoc(docRef, { ...newProfile });
      user.value = newProfile;
      users.value[userId] = newProfile;
      return true;
    } catch (e) {
      return false;
    }
  };

  const updateProfileImage = async (downloadUrl: string) => {
    const userId = currentUserId.value;
    if (!userId) return;
    const docRef = doc(firestore, 'users', userId);
    try {
      await updateDoc(docRef, { photoUrl: downloadUrl });
      user.value = { ...user.value, photoUrl: downloadUrl };
      users.value[userId] = user.value;
      return true;
    } catch (e) {
      return false;
    }
  };

  const saveFile = async (fullPath: string, file: any) => {
    const { storage, ref } = useFirebase();
    const imageRef = ref(storage, fullPath);
    const snapshot = await uploadString(imageRef, file, 'data_url');
    if (snapshot) {
      const downloadUrl = await getDownloadURL(snapshot.ref);
      const metadata = await getMetadata(imageRef);
      return { snapshot, downloadUrl, metadata };
    }
  };

  const uploadProfile = async (file: any) => {
    return await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        const result = reader.result;
        if (!result) return reject();
        const saveResult = await saveFile('profileImages/' + file.name, result);
        if (!saveResult) return reject();
        const { snapshot, downloadUrl } = saveResult;
        if (snapshot) {
          updateProfileImage(downloadUrl);
          resolve(saveResult);
        } else {
          reject();
        }
      };
    });
  };

  const getAllUsers = async () => {
    const { firestore } = useFirebase();
    const userCollection = await getDocs(collection(firestore, 'users'));
    const getUsersCollection: UserInfo[] = [];
    userCollection.forEach((doc) => {
      getUsersCollection.push({ ...doc.data(), id: doc.id });
    });
    return getUsersCollection;
  };

  const blockUser = async (userId: string) => {
    const currentUser = currentUserId.value;
    if (!currentUser) return;
    const docRef = doc(firestore, 'users', currentUser);
    try {
      await updateDoc(docRef, { blocks: arrayUnion(userId) });
      if (user.value.blocks) {
        user.value.blocks.push(userId);
      } else {
        user.value.blocks = [userId];
      }
      return true;
    } catch (e) {
      return false;
    }
  };

  const unblockUser = async (userId: string) => {
    const currentUser = currentUserId.value;
    if (!currentUser) return;
    const docRef = doc(firestore, 'users', currentUser);
    try {
      await updateDoc(docRef, { blocks: arrayRemove(userId) });
      await getUserProfile(currentUser, true);
      return true;
    } catch (e) {
      return false;
    }
  };

  return {
    currentUserId,
    user,
    users,
    clearUser,
    setUser,
    getUserProfile,
    uploadProfile,
    updateProfile,
    updateProfileImage,
    createProfile,
    getAllUsers,
    blockUser,
    unblockUser,
  };
});
