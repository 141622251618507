// create user
import {
  getAuth,
  signInWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  AuthProvider,
} from 'firebase/auth';
import { useUserStore } from '~~/stores/user';

export const LOCAL_STORAGE_KEY = 'user';

export const loading = ref(false);

export const useAuth = () => {
  // create user
  const createUser = async (email: any, password: any) => {
    const auth = getAuth();
    loading.value = true;
    const credentials = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    )
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      })
      .finally(() => {
        loading.value = false;
      });
    return credentials;
  };

  // /signInUser user
  const signInUser = async (email: string, password: string) => {
    loading.value = true;
    const auth = getAuth();
    const credentials = await signInWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      })
      .finally(() => {
        loading.value = false;
      });
    return credentials;
  };

  // initUser user
  const userStore = useUserStore();

  const initUser = async () => {
    const auth = getAuth();
    const route = useRoute();
    onAuthStateChanged(auth, async (fireUser) => {
      if (fireUser) {
        let userInfo = await userStore.getUserProfile(fireUser.uid, true);
        if (!userInfo) {
          // Create the user profile if it doesn't exist
          await userStore.createProfile(fireUser);
          userInfo = await userStore.getUserProfile(fireUser.uid, true);
        }
        userStore.setUser(userInfo!);
        console.warn('Logged in', userInfo);
        // Check the route for a redirect
        const redirect = route.query.redirect;
        if (redirect) {
          // Redirect to the redirect
          navigateTo(redirect as string);
        }
      } else {
        console.warn('Logged out');
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        userStore.clearUser();
        const route = useRoute();
        // Check the route for a redirect
        if (route.query.redirect) {
          // Redirect to the login with the redirect
          navigateTo(`/login?redirect=${route.query.redirect}`);
        } else {
          navigateTo('/login');
        }
      }
    });
  };

  // signout
  const signOutUser = async () => {
    const auth = getAuth();
    await auth.signOut();
  };

  // Google Authentication
  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    let credentials;
    try {
      loading.value = true;
      credentials = await signInWithPopup(auth, provider);

      redirectUser();
    } catch (error: any) {
      // Handle Errors here.
      console.log('loginWithGoogle error:', error);
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    } finally {
      loading.value = false;
    }
    return credentials;
  };

  // facebook authentication
  const loginWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    const auth = getAuth();
    loading.value = true;
    let credentials;
    try {
      credentials = await signInWithPopup(auth, provider);
      redirectUser();
    } catch (error: any) {
      console.log('loginWithFacebook', error);
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = FacebookAuthProvider.credentialFromError(error);

      // ...
    } finally {
      loading.value = false;
    }
    return credentials;
  };

  const redirectUser = () => {
    const route = useRoute();
    // Check the route for a redirect
    const redirect = route.query.redirect;
    if (redirect) {
      // Redirect the user
      navigateTo(redirect as string, { replace: true });
    }
    navigateTo('/profile', { replace: true });
  };

  return {
    loginWithGoogle,
    loginWithFacebook,
    createUser,
    signInUser,
    initUser,
    signOutUser,
    loading,
  };
};
