import { useUserStore } from '~~/stores/user';

export default defineNuxtRouteMiddleware((to, from) => {
  const userStore = useUserStore();
  if (!userStore.user.id && to.path !== '/login' && to.path !== '/signup') {
    console.warn('No user found, redirecting to login');
    return navigateTo('/login?redirect=' + to.path);
  }
  if (userStore.user.id && to.path == '/') {
    console.warn('User found, redirecting to profile');
    return navigateTo('/profile');
  }
});
