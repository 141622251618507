import { default as reportszwRqbqNKv2Meta } from "/vercel/path0/pages/admin/reports.vue?macro=true";
import { default as verifyFWejvkksGgMeta } from "/vercel/path0/pages/admin/verify.vue?macro=true";
import { default as sign_45inUPhFuSgHrGMeta } from "/vercel/path0/pages/auth/sign-in.vue?macro=true";
import { default as sign_45uplvcUsYQwv1Meta } from "/vercel/path0/pages/auth/sign-up.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
import { default as _91id_932tpKFmVP1LMeta } from "/vercel/path0/pages/profile/edit/[id].vue?macro=true";
import { default as indexSsrwCXeuTQMeta } from "/vercel/path0/pages/profile/index.vue?macro=true";
import { default as new2xgvQDan4fMeta } from "/vercel/path0/pages/profile/new.vue?macro=true";
import { default as _91reviewId_93bms3HtY7xkMeta } from "/vercel/path0/pages/reviews/edit/[reviewId].vue?macro=true";
import { default as indexmyTA402mXcMeta } from "/vercel/path0/pages/reviews/index.vue?macro=true";
import { default as _91userId_93FIyDW1geFCMeta } from "/vercel/path0/pages/reviews/new/[userId].vue?macro=true";
import { default as _91reviewId_93LF2EAbyHHfMeta } from "/vercel/path0/pages/reviews/view/[reviewId].vue?macro=true";
export default [
  {
    name: reportszwRqbqNKv2Meta?.name ?? "admin-reports",
    path: reportszwRqbqNKv2Meta?.path ?? "/admin/reports",
    children: [],
    meta: reportszwRqbqNKv2Meta || {},
    alias: reportszwRqbqNKv2Meta?.alias || [],
    redirect: reportszwRqbqNKv2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/reports.vue").then(m => m.default || m)
  },
  {
    name: verifyFWejvkksGgMeta?.name ?? "admin-verify",
    path: verifyFWejvkksGgMeta?.path ?? "/admin/verify",
    children: [],
    meta: verifyFWejvkksGgMeta || {},
    alias: verifyFWejvkksGgMeta?.alias || [],
    redirect: verifyFWejvkksGgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/verify.vue").then(m => m.default || m)
  },
  {
    name: sign_45inUPhFuSgHrGMeta?.name ?? "auth-sign-in",
    path: sign_45inUPhFuSgHrGMeta?.path ?? "/auth/sign-in",
    children: [],
    meta: sign_45inUPhFuSgHrGMeta || {},
    alias: sign_45inUPhFuSgHrGMeta?.alias || [],
    redirect: sign_45inUPhFuSgHrGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45uplvcUsYQwv1Meta?.name ?? "auth-sign-up",
    path: sign_45uplvcUsYQwv1Meta?.path ?? "/auth/sign-up",
    children: [],
    meta: sign_45uplvcUsYQwv1Meta || {},
    alias: sign_45uplvcUsYQwv1Meta?.alias || [],
    redirect: sign_45uplvcUsYQwv1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth/sign-up.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    children: [],
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexoOBDY93e9EMeta?.name ?? "login",
    path: indexoOBDY93e9EMeta?.path ?? "/login",
    children: [],
    meta: indexoOBDY93e9EMeta || {},
    alias: indexoOBDY93e9EMeta?.alias || [],
    redirect: indexoOBDY93e9EMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_932tpKFmVP1LMeta?.name ?? "profile-edit-id",
    path: _91id_932tpKFmVP1LMeta?.path ?? "/profile/edit/:id",
    children: [],
    meta: _91id_932tpKFmVP1LMeta || {},
    alias: _91id_932tpKFmVP1LMeta?.alias || [],
    redirect: _91id_932tpKFmVP1LMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/profile/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexSsrwCXeuTQMeta?.name ?? "profile",
    path: indexSsrwCXeuTQMeta?.path ?? "/profile",
    children: [],
    meta: indexSsrwCXeuTQMeta || {},
    alias: indexSsrwCXeuTQMeta?.alias || [],
    redirect: indexSsrwCXeuTQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: new2xgvQDan4fMeta?.name ?? "profile-new",
    path: new2xgvQDan4fMeta?.path ?? "/profile/new",
    children: [],
    meta: new2xgvQDan4fMeta || {},
    alias: new2xgvQDan4fMeta?.alias || [],
    redirect: new2xgvQDan4fMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/profile/new.vue").then(m => m.default || m)
  },
  {
    name: _91reviewId_93bms3HtY7xkMeta?.name ?? "reviews-edit-reviewId",
    path: _91reviewId_93bms3HtY7xkMeta?.path ?? "/reviews/edit/:reviewId",
    children: [],
    meta: _91reviewId_93bms3HtY7xkMeta || {},
    alias: _91reviewId_93bms3HtY7xkMeta?.alias || [],
    redirect: _91reviewId_93bms3HtY7xkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/reviews/edit/[reviewId].vue").then(m => m.default || m)
  },
  {
    name: indexmyTA402mXcMeta?.name ?? "reviews",
    path: indexmyTA402mXcMeta?.path ?? "/reviews",
    children: [],
    meta: indexmyTA402mXcMeta || {},
    alias: indexmyTA402mXcMeta?.alias || [],
    redirect: indexmyTA402mXcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: _91userId_93FIyDW1geFCMeta?.name ?? "reviews-new-userId",
    path: _91userId_93FIyDW1geFCMeta?.path ?? "/reviews/new/:userId",
    children: [],
    meta: _91userId_93FIyDW1geFCMeta || {},
    alias: _91userId_93FIyDW1geFCMeta?.alias || [],
    redirect: _91userId_93FIyDW1geFCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/reviews/new/[userId].vue").then(m => m.default || m)
  },
  {
    name: _91reviewId_93LF2EAbyHHfMeta?.name ?? "reviews-view-reviewId",
    path: _91reviewId_93LF2EAbyHHfMeta?.path ?? "/reviews/view/:reviewId",
    children: [],
    meta: _91reviewId_93LF2EAbyHHfMeta || {},
    alias: _91reviewId_93LF2EAbyHHfMeta?.alias || [],
    redirect: _91reviewId_93LF2EAbyHHfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/reviews/view/[reviewId].vue").then(m => m.default || m)
  }
]